import React from 'react';
import logo from '../images/accredian-logo.png'
import { Button } from '@mui/material';
export const Navbar = () => {
  return (
    <header>
        
        <nav class="navbar navbar-expand-lg bg-white border border-bottom fixed-top">
            <div class="container-fluid px-3">
                <a target='_blank' class="navbar-brand" href="https://accredian.com"> <img src={logo} width="250px"/></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" fdprocessedid="woydb3">
                    <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                        <ul style={{color:"black",fontWeight:"bold",fontSize:"18px"}} class="navbar-nav mr-auto mb-2 mb-lg-0">
                            <li class="nav-item mx-1">
                            <a style={{color:"#000"}} class="nav-link" aria-current="page" href="#about-us" >About Us</a>
                            </li>
                            <li class="nav-item mx-1"><a style={{color:"#000"}} class="nav-link" href="#sol" >Solution Offered</a></li>
                            <li class="nav-item mx-1"><a style={{color:"#000"}} class="nav-link" href="#industry" >Industries</a></li>
                            <li class="nav-item mx-1"><a style={{color:"#000"}} class="nav-link" href="#process" >Process</a>
                            </li><li class="nav-item mx-1"><a style={{color:"#000"}} class="nav-link" href="#usps" >USPs</a>
                            </li>
                            {/* <li class="nav-item mx-2"><a class="nav-link btn btn-primary text-white text-normal" target="_blank" href="https://insaid.co">Back to Home Page</a>
                            </li> */}
                            <li className="nav-item  ">
                    <Button href="https://accredian.com" type="button" class="btn btn-primary mt-1">Back to Home Page</Button>
                    </li>
                            </ul>
                            </div>
                            </div>
                            </nav>
    </header>
  )
}

export default Navbar
